// eslint-disable-next-line
import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {scanMalware} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const MalwareScanner = () => {
  const {applicationId} = useParams()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [scanResult, setScanResult] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showResults, setShowResults] = useState(false)

  const highlightStatus = (text: string) => {
    const warningPattern = /warn?ing?:+/gi
    const errorPattern = /error:+/gi
    const succssPattern = /success:+/gi

    // replace matched words with highlighted HTML
    let highlightedText = text
      .replace(warningPattern, '<span style="color: yellow;">$&</span>')
      .replace(errorPattern, '<span style="color: red;">$&</span>')
      .replace(succssPattern, '<span style="color: green;">$&</span>')

    return highlightedText
  }

  const handleScanMalware = async () => {
    setShowConfirmation(false)
    setLoading(true)
    setScanResult([])
    setError(undefined)
    setShowResults(true)

    try {
      const result = await scanMalware(applicationId ?? '')
      if (Array.isArray(result)) {
        setScanResult(result)
        notifySuccess('Malware scan completed successfully')
      } else {
        throw new Error('Malware scan returned an invalid result')
      }
    } catch (error) {
      setError('Malware scan failed: ' + (error as Error).message)
      notifyError('Malware scan failed: ' + (error as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='container-fluid'>
      <TabHeader heading='Malware Scanner' />

      <TabBody>
        <div className='text-primary'>
          Scan your application for malwares and receive a detailed report. This scan validates core
          files and plugin(s) changes, if any.
        </div>

        <div className='mt-4'></div>

        <button
          className='btn btn-primary'
          onClick={() => setShowConfirmation(true)}
          disabled={loading}
        >
          Start Scan
        </button>

        {showConfirmation && (
          <ConfirmSuccessModal
            setIsOpen={setShowConfirmation}
            title='Continue?'
            btnTitle='Scan'
            content='Your application will be scanned for malwares. This may take some time based on your file size.'
            successFunction={handleScanMalware}
          />
        )}

        {showResults && (
          <div className='mt-5'>
            {error ? (
              <div className='alert alert-danger d-flex align-items-center p-5'>
                <div className='d-flex flex-column'>
                  <span>{error}</span>
                </div>
              </div>
            ) : loading ? (
              <div className='w-100 text-center my-10'>
                <span className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            ) : scanResult.length <= 0 ? (
              <div className='alert alert-primary d-flex align-items-center p-5 w-100'>
                <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <div className='d-flex flex-column'>
                  <span>No Malware Found</span>
                </div>
              </div>
            ) : (
              <div className='bg-dark rounded-1 py-6 px-6'>
                <div
                  className='w-100'
                  style={{
                    maxHeight: '600px',
                    overflowY: 'scroll',
                    scrollbarColor: '#fff',
                    scrollbarWidth: 'auto',
                    scrollbarGutter: 'stable',
                  }}
                >
                  {scanResult.map((line, index) => (
                    <div key={index}>
                      <p
                        className='text-white text-break'
                        dangerouslySetInnerHTML={{__html: highlightStatus(line)}}
                      ></p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default MalwareScanner

import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import KnowledgeBaseAccordion from '../common/knowledgebase/KnowledgeBaseAccordion'
import TTFB from './ttfb/tool/TTFB'

const toolBreadcrumbs: Array<PageLink> = [
  {
    title: 'Tools',
    path: '/tools/ttfb',
    isSeparator: false,
    isActive: false,
  },
]

const ToolsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ttfb'
          element={
            <>
              <PageTitle breadcrumbs={toolBreadcrumbs}>TTFB</PageTitle>
              <KnowledgeBaseAccordion
                body={`Measure your websites TTFB (time to first byte) from different geographic locations
                  <br/>
                  <br/>
                  You can also click on the arrow at right of every row and see a <b>detailed waterfall</b> of the load time.`}
              />
              <TTFB />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/tools/ttfb' />} />
    </Routes>
  )
}

export default ToolsPage

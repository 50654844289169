import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {DNS} from './domain-settings/components/dns/DNS'
import {DomainHeader} from './domain-settings/DomainHeader'
import {SSL} from './domain-settings/components/ssl/SSL'
import {Caching} from './domain-settings/components/caching/Caching'
import Speed from './domain-settings/components/speed/Speed'
import {DomainRecordsWrapper} from './domain-settings/components/dns/domain-records/DomainRecords'
import NsRecords from './domain-settings/ns-records/NsRecords'

const cloudflareDomainBreadcrumbs: Array<PageLink> = [
  {
    title: 'CloudflareDomain Settings',
    path: '/cloudflareDomain/:cloudflareDomainId/dns',
    isSeparator: false,
    isActive: false,
  },
]

const DomainPage = () => {
  return (
    <Routes>
      <Route
        path=':cloudflareDomainId/ns'
        element={
          <>
            <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>NameServers</PageTitle>
            <NsRecords />
          </>
        }
      />
      <Route
        element={
          <>
            <DomainHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path=':cloudflareDomainId/records'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>DNS Records</PageTitle>
              <DomainRecordsWrapper />
            </>
          }
        />

        <Route
          path=':cloudflareDomainId/dns'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>DNS</PageTitle>
              <DNS />
            </>
          }
        />

        <Route
          path=':cloudflareDomainId/ssl'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>SSL</PageTitle>
              <SSL />
            </>
          }
        />

        <Route
          path=':cloudflareDomainId/speed/*'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>Speed</PageTitle>
              <Speed />
            </>
          }
        />

        <Route
          path=':cloudflareDomainId/caching/*'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareDomainBreadcrumbs}>Caching</PageTitle>
              <Caching />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/cloudflareDomain/:cloudflareDomainId/records' />} />
    </Routes>
  )
}

export default DomainPage

import React, {useEffect, useState} from 'react'
import {
  getDomainByApplication,
  getDomainStatus,
  installCertificate,
} from '../../../../applications-list/core/_requests'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

function SSL() {
  const {applicationId} = useParams()

  const [domain, setDomain] = useState<{domain_name: string; is_valid_a_record: boolean}>()
  const [isCertificateInstalled, setIsCertificateInstalled] = useState(false)
  const [isCertificateRequested, setIsCertificateRequested] = useState(false)
  const [isCertificateFailed, setIsCertificateFailed] = useState(false)

  const fetchStatus = () => {
    getDomainStatus(applicationId ?? '').then((resp) => {
      if (resp?.status === 'CERTIFICATE_ISSUED') {
        setIsCertificateRequested(false)
        setIsCertificateInstalled(true)
      } else if (resp?.status === 'CERTIFICATE_REQUESTED') {
        setIsCertificateRequested(true)
        setIsCertificateInstalled(false)
        setTimeout(fetchStatus, 2000)
      } else if (resp?.status === 'CERTIFICATE_ISSUE_FAILED') {
        setIsCertificateFailed(true)
      }
    })
  }

  const installCertificateHandler = () => {
    installCertificate(applicationId ?? ' ')
      .then(() => {
        notifySuccess('SSL certificate installation started')
        setTimeout(fetchStatus, 1000)
      })
      .catch((error) => {
        notifyError('SSL certificate installation failed: ' + error.message)
      })
  }

  useEffect(() => {
    getDomainByApplication(applicationId ?? '').then((resp) => {
      if (resp?.domain_name) setDomain(resp)
    })
    fetchStatus()
  }, [])

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='dc_access_details_view'>
      <TabHeader heading='SSL' />
      <TabBody>
        {isCertificateFailed && (
          <div className='alert alert-danger d-flex align-items-center p-4'>
            <div className='d-flex flex-column'>
              <p className='mb-1'>
                * Seems like your previous operation has failed. Please retry after some time to
                allow DNS propagation to complete properly.
              </p>
            </div>
          </div>
        )}

        {domain && domain.is_valid_a_record ? (
          <>
            <p className='fs-6 text-gray-600 mb-6'>
              Deploy and activate SSL certificates on your domain
            </p>
            <button
              disabled={isCertificateInstalled || isCertificateRequested}
              onClick={installCertificateHandler}
              className='btn btn-primary w-100'
            >
              {isCertificateRequested ? (
                <>
                  Installing{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </>
              ) : isCertificateInstalled ? (
                <>Certificate Installed</>
              ) : (
                <>
                  <i className='bi bi-chat-square-text-fill fs-4 me-2'></i> Activate SSL
                </>
              )}
            </button>
          </>
        ) : (
          <div className='alert alert-danger d-flex align-items-center'>
            <span className='fs-6'>
              Please add a domain and its A record to activate SSL section.
              <Link to={`/application/${applicationId}/domain-manager/domain`}> Click Here</Link>.
            </span>
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default SSL

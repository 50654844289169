import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ServersListWrapper} from './servers-list/ServersList'
import KnowledgeBaseAccordion from '../common/knowledgebase/KnowledgeBaseAccordion'

const serversBreadcrumbs: Array<PageLink> = [
  {
    title: 'Server Management',
    path: '/servers/list',
    isSeparator: false,
    isActive: false,
  },
]

const ServersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={serversBreadcrumbs}>Server List</PageTitle>
              <KnowledgeBaseAccordion
                body={`The server list page provides the following details
                <ol>
                  <li><b>Server Names</b>: The server names assigned to the server by the user</li>
                  <li><b>Server IPs</b>: The static server IPs</li>
                  <li><b>Server States</b>: The current server state (STARTED, STOPPED, STARTING, STOPPING, RESTARTING)</li>
                  <li><b>Server Actions</b>: The available server actions include Restart, Start/Stop, Edit. (Note that these actions are not not available for a custom server)</li>
                </ol>
                The <mark className="bg-primary"><b>Launch Server</b></mark> button is to be used to launch a new server.`}
              />
              <ServersListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/servers/list' />} />
    </Routes>
  )
}

export default ServersPage

import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DomainsListWrapper} from './domains-list/DomainsList'
import KnowledgeBaseAccordion from '../common/knowledgebase/KnowledgeBaseAccordion'

const domainsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Domain Management',
    path: '/domains/list',
    isSeparator: false,
    isActive: false,
  },
]

const DomainsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={domainsBreadcrumbs}>Domain List</PageTitle>
              <KnowledgeBaseAccordion
                body={`The domain list page allows you to <b>add your domain and manage its dns</b> from Cloudphant itself.
                <br/>
                <br/>
                The domain list page provides the following details
                <ol>
                  <li><b>Domain Name</b>: The domain name entered by the user.</li>
                  <li><b>Domain Status</b>: The status of the domain (Decided based upon the addition and verification of nameservers).</li>
                  <li><b>Domain Actions</b>: The available Domain actions include edit and delete.</li>
                </ol>
                The <mark className="bg-primary"><b>Add Domain</b></mark> button is to be used to add a new domain.`}
              />
              <DomainsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/domains/list' />} />
    </Routes>
  )
}

export default DomainsPage

import axios, {AxiosResponse} from 'axios'
import {ID, PaginationResponse, Response} from '../../../../../_metronic/helpers'
import {
  AccessDetailsDto,
  Application,
  UpdateApplication,
  ApplicationMetadata,
  ApplicationStaging,
  Backup,
  ApplicationPageCache,
  ApplicationDatabaseOptimizations,
  ApplicationWebPOptimization,
  BackupSettings,
  ApplicationBrowserCache,
  ApplicationObjectCache,
  FTPDetails,
  HTMLMinifyObject,
  ApplicationEdgeCache,
  CreateApplicationCron,
  UpdateApplicationCron,
  MalwareScanResult,
  FirewallResponse,
  UpdateFirewallRequest,
  PhpExecutionResponse,
  UpdatePhpExecutionRequest,
  DirectoryBrowsingResponse,
  UpdateDirectoryBrowsingRequest,
  LockPluginThemeResponse,
  UpdateLockPluginThemeRequest,
  FileEditorResponse,
  UpdateFileEditorRequest,
  XmlRpcResponse,
  UpdateXmlRpcRequest,
  HotlinkProtectionResponse,
  UpdateHotlinkProtectionRequest,
  SensitiveFilesAccessResponse,
  UpdateSensitiveFilesAccessRequest,
} from './_models'
import {requestWrapper} from '../../../../utils/RequestWrapper'

const API_URL = process.env.REACT_APP_THEME_API_URL
const APPLICATION_URL = `${API_URL}/v1/application`

const getApplications = (query: string): Promise<PaginationResponse<Application[]> | undefined> => {
  return axios
    .get(`${APPLICATION_URL}?${query}`)
    .then((d: AxiosResponse<Response<PaginationResponse<Application[]>>>) => d.data)
    .then((d: Response<PaginationResponse<Application[]>>) => d.data)
}

const getApplicationWithMetadata = (id: string): Promise<ApplicationMetadata | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/metadata/${id}`)
    .then((response: AxiosResponse<Response<ApplicationMetadata>>) => response.data)
    .then((response: Response<ApplicationMetadata>) => response.data)
}

const getApplicationById = (id: ID): Promise<Application | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Application>>) => response.data)
    .then((response: Response<Application>) => response.data)
}

const updateApplication = async (
  id: ID,
  application: UpdateApplication
): Promise<boolean | undefined> => {
  const response = await axios.patch(`${APPLICATION_URL}/${id}`, application)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getApplicationAccessLogs = (id: ID, numLines: number): Promise<string[] | undefined> => {
  return requestWrapper(axios.get(`${APPLICATION_URL}/${id}/access-logs/?numLines=${numLines}`))
}

const getApplicationErrorLogs = (id: ID, numLines: number): Promise<string[] | undefined> => {
  return requestWrapper(axios.get(`${APPLICATION_URL}/${id}/error-logs/?numLines=${numLines}`))
}

const createApplication = async (application: Application): Promise<boolean | undefined> => {
  const response = await axios.post(APPLICATION_URL, application)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteApplication = async (applicationId: ID): Promise<boolean | undefined> => {
  const response = await axios.delete(`${APPLICATION_URL}/${applicationId}`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteSelectedApplications = (applicationIds: Array<ID>): Promise<void> => {
  const requests = applicationIds.map((id) => axios.delete(`${APPLICATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getAccessDetails = (applicationId: string): Promise<AccessDetailsDto | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/server-services-access-detail/${applicationId}`)
    .then((response: AxiosResponse<Response<AccessDetailsDto>>) => response.data)
    .then((response: Response<AccessDetailsDto>) => response.data)
}

const getFTPDetails = (applicationId: string): Promise<FTPDetails | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/application-ftp-auth-detail`)
    .then((response: AxiosResponse<Response<FTPDetails>>) => response.data)
    .then((response: Response<FTPDetails>) => response.data)
}

const getDomainByApplication = (
  applicationId: string
): Promise<{domain_name: string; is_valid_a_record: boolean; a_record: string} | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/domain/record-status`)
    .then(
      (
        response: AxiosResponse<
          Response<{domain_name: string; is_valid_a_record: boolean; a_record: string}>
        >
      ) => response.data
    )
    .then(
      (response: Response<{domain_name: string; is_valid_a_record: boolean; a_record: string}>) =>
        response.data
    )
}

const getDomainStatus = (applicationId: string): Promise<{status: string} | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/domain/status`)
    .then((response: AxiosResponse<Response<{status: string}>>) => response.data)
    .then((response: Response<{status: string}>) => response.data)
}

const addDomainToApplication = async (
  applicationId: string,
  domain: string
): Promise<{status: string} | undefined> => {
  const response = await axios.patch(`${APPLICATION_URL}/${applicationId}/domain/`, {
    custom_domain: domain,
  })
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getStagingStatusByApplication = (
  applicationId: string
): Promise<ApplicationStaging | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/staging`)
    .then((response: AxiosResponse<Response<ApplicationStaging>>) => response.data)
    .then((response: Response<ApplicationStaging>) => response.data)
}

const updateApplicationStagingStatus = async (
  applicationId: string,
  staging_state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/staging`, {
    staging_state: staging_state,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const updateApplicationSyncStatus = async (applicationId: string): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/staging/sync`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getDatabaseOptimizationList = (
  applicationId: string
): Promise<ApplicationDatabaseOptimizations | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/database/list`)
    .then((response: AxiosResponse<Response<ApplicationDatabaseOptimizations>>) => response.data)
    .then((response: Response<ApplicationDatabaseOptimizations>) => response.data)
}

const updateApplicationDatabaseOptimizations = async (
  applicationId: string,
  types: string[]
): Promise<boolean | undefined> => {
  const response = await axios.post<{code?: string; message?: string; data: boolean}>(
    `${APPLICATION_URL}/${applicationId}/optimization/database/bulk`,
    {
      types,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getWebPOptimization = (
  applicationId: string
): Promise<ApplicationWebPOptimization | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/webp`)
    .then((response: AxiosResponse<Response<ApplicationWebPOptimization>>) => response.data)
    .then((response: Response<ApplicationWebPOptimization>) => response.data)
}

const updateWebPOptimization = async (
  applicationId: string,
  values: ApplicationWebPOptimization
): Promise<boolean | undefined> => {
  const response = await axios.post<{code?: string; message?: string; data: boolean}>(
    `${APPLICATION_URL}/${applicationId}/optimization/webp`,
    values
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getPageCacheStateByApplication = (
  applicationId: string
): Promise<ApplicationPageCache | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/page-cache/state`)
    .then((response: AxiosResponse<Response<ApplicationPageCache>>) => response.data)
    .then((response: Response<ApplicationPageCache>) => response.data)
}

const updateApplicationPageCacheState = async (
  applicationId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/page-cache/state`,
    {
      state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const purgeApplicationCache = async (
  applicationId: string,
  body: any
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/page-cache/purge`,
    body
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getHTMLMinifyStateByApplication = async (
  applicationId: string
): Promise<HTMLMinifyObject | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/html-minify/state`)
    .then((response: AxiosResponse<Response<HTMLMinifyObject>>) => response.data)
    .then((response: Response<HTMLMinifyObject>) => response.data)
}

const updateApplicationMinifyHTMLState = async (
  applicationId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/html-minify/state`,
    {
      state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getBrowserCacheByApplication = (
  applicationId: string
): Promise<ApplicationBrowserCache | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/browser-cache`)
    .then((response: AxiosResponse<Response<ApplicationBrowserCache>>) => response.data)
    .then((response: Response<ApplicationBrowserCache>) => response.data)
}

const updateApplicationBrowserCache = async (
  applicationId: string,
  values: ApplicationBrowserCache
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/browser-cache`,
    values
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getObjectCacheStateByApplication = (
  applicationId: string
): Promise<ApplicationObjectCache | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/object-cache/state`)
    .then((response: AxiosResponse<Response<ApplicationObjectCache>>) => response.data)
    .then((response: Response<ApplicationObjectCache>) => response.data)
}

const updateApplicationObjectCacheState = async (
  applicationId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/object-cache/state`,
    {
      state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getEdgeCacheStateByApplication = async (
  applicationId: string
): Promise<ApplicationEdgeCache | undefined> => {
  const response = await axios.get(
    `${APPLICATION_URL}/${applicationId}/optimization/edge-cache/state`
  )

  if (response.data.code) {
    throw new Error(JSON.stringify(response.data))
  }
  return response.data.data
}

const updateApplicationEdgeCacheState = async (
  applicationId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/edge-cache/state`,
    {
      state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const purgeApplicationObjectCache = async (applicationId: string): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/object-cache/purge`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const purgeApplicationOPCache = async (applicationId: string): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/optimization/opcache/purge`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const initiateApplicationBackup = async (
  applicationId: string,
  body: any
): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/backup/init`, body)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getApplicationBackups = (applicationId: string): Promise<Backup[] | undefined> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/backup/all`)
    .then((d: AxiosResponse<Response<Backup[]>>) => d.data)
    .then((d: Response<Backup[]>) => d.data)
}

const getBackupSettings = async (applicationId: string): Promise<BackupSettings | undefined> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/backup/setting`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const setScheduledBackupSettings = async (
  applicationId: string,
  backupSettings: BackupSettings
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/backup/setting`,
    backupSettings
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const restoreApplicationBackup = async (
  applicationId: string,
  backupId: string
): Promise<boolean | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/backup/${backupId}/restore`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const deleteApplicationBackup = async (
  applicationId: string,
  backupId: string
): Promise<boolean | undefined> => {
  const response = await axios.delete(`${APPLICATION_URL}/${applicationId}/backup/${backupId}`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const installCertificate = async (applicationId: string): Promise<{status: string} | undefined> => {
  const response = await axios.post(
    `${APPLICATION_URL}/${applicationId}/domain/install-certificate`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getDatabaseRepairList = async (applicationId: string): Promise<any> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/optimization/database/repair/list`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((data: any) => data)
}

const repairDatabase = (
  applicationId: string,
  type: string,
  tables: string[]
): Promise<boolean> => {
  const requestBody = {
    type: type,
    tables: tables,
  }

  return axios
    .post(`${APPLICATION_URL}/${applicationId}/optimization/database/repair`, requestBody)
    .then((response) => response.data)
}

const getNewrelicService = async (applicationId: ID) => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/service/newrelic`)
  return response.data
}

const updateNewrelicService = async (
  applicationId: string,
  state: boolean
): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/service/newrelic`, {
    state,
  })

  return response.data
}

const getApplicationCrons = async (applicationId: string): Promise<any> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/cron`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((d: any) => d.data)
}

const createApplicationCron = async (
  applicationId: string,
  cron: CreateApplicationCron
): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/cron`, cron)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateApplicationCron = async (
  applicationId: string,
  cronId: string,
  cron: UpdateApplicationCron
): Promise<boolean | undefined> => {
  const response = await axios.patch(`${APPLICATION_URL}/${applicationId}/cron/${cronId}`, cron)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const deleteApplicationCron = async (
  applicationId: string,
  cronId: string
): Promise<boolean | undefined> => {
  const response = await axios.delete(`${APPLICATION_URL}/${applicationId}/cron/${cronId}`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getUptime = async (applicationId: string): Promise<any> => {
  return axios
    .get(`${APPLICATION_URL}/${applicationId}/uptime`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((d: any) => d.data)
}

const createUptime = async (applicationId: string, url: string): Promise<boolean | undefined> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/uptime`, {url})

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteUptime = async (applicationId: string): Promise<boolean | undefined> => {
  const response = await axios.delete(`${APPLICATION_URL}/${applicationId}/uptime`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const scanMalware = async (applicationId: string): Promise<MalwareScanResult | undefined> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/malware-scanner`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getSensitiveFilesAccessDetails = async (
  applicationId: string
): Promise<SensitiveFilesAccessResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/sensitive-files`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateSensitiveFilesAccessDetails = async (
  applicationId: string,
  data: UpdateSensitiveFilesAccessRequest
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/sensitive-files`,
    data
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getFirewallDetails = async (applicationId: string): Promise<FirewallResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/firewall`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateFirewallDetails = async (
  applicationId: string,
  data: UpdateFirewallRequest
): Promise<boolean> => {
  const response = await axios.put(`${APPLICATION_URL}/${applicationId}/security/firewall`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getPhpExecutionDetails = async (applicationId: string): Promise<PhpExecutionResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/php-execution`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updatePhpExecutionDetails = async (
  applicationId: string,
  data: UpdatePhpExecutionRequest
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/php-execution`,
    data
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getLockPluginThemeDetails = async (
  applicationId: string
): Promise<LockPluginThemeResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/lock-plugin-theme`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateLockPluginThemeDetails = async (
  applicationId: string,
  data: UpdateLockPluginThemeRequest
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/lock-plugin-theme`,
    data
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getFileEditorDetails = async (applicationId: string): Promise<FileEditorResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/file-editor`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateFileEditorDetails = async (
  applicationId: string,
  data: UpdateFileEditorRequest
): Promise<boolean> => {
  const response = await axios.put(`${APPLICATION_URL}/${applicationId}/security/file-editor`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getSecurityHeaders = async (
  applicationId: string
): Promise<{state: boolean; settings: Record<string, string>}> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/security-headers`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateSecurityHeaders = async (
  applicationId: string,
  values: {state: boolean; settings: Record<string, string>}
): Promise<boolean | undefined> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/security-headers`,
    values
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getXmlRpcAccessDetails = async (applicationId: string): Promise<XmlRpcResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/xmlrpc`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateXmlRpcAccessDetails = async (
  applicationId: string,
  data: UpdateXmlRpcRequest
): Promise<boolean> => {
  const response = await axios.put(`${APPLICATION_URL}/${applicationId}/security/xmlrpc`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getHotlinkProtectionDetails = async (
  applicationId: string
): Promise<HotlinkProtectionResponse> => {
  const response = await axios.get(
    `${APPLICATION_URL}/${applicationId}/security/hotlink-protection`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateHotlinkProtectionDetails = async (
  applicationId: string,
  data: UpdateHotlinkProtectionRequest
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/hotlink-protection`,
    data
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getPHPConf = async (applicationId: string): Promise<string> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/php-settings/conf`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getLockCoreFilesDetails = async (
  applicationId: string
): Promise<HotlinkProtectionResponse> => {
  const response = await axios.get(`${APPLICATION_URL}/${applicationId}/security/lock-core-files`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updatePHPConf = async (applicationId: string, conf: string): Promise<string> => {
  const response = await axios.post(`${APPLICATION_URL}/${applicationId}/php-settings/conf`, {conf})
  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateLockCoreFilesDetails = async (
  applicationId: string,
  data: UpdateHotlinkProtectionRequest
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/security/lock-core-files`,
    data
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getCSSMinificationState = async (applicationId: string): Promise<{state: boolean}> => {
  const response = await axios.get(
    `${APPLICATION_URL}/${applicationId}/optimization/css/minify/state`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateCSSMinificationState = async (
  applicationId: string,
  data: {state: boolean}
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/optimization/css/minify/state`,
    {
      state: data.state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data
}

const purgeCSSMinificationCache = async (applicationId: string): Promise<boolean> => {
  const response = await axios.delete(
    `${APPLICATION_URL}/${applicationId}/optimization/css/minify/purge`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data
}

const getJSMinificationState = async (applicationId: string): Promise<{state: boolean}> => {
  const response = await axios.get(
    `${APPLICATION_URL}/${applicationId}/optimization/js/minify/state`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateJSMinificationState = async (
  applicationId: string,
  data: {state: boolean}
): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/optimization/js/minify/state`,
    {
      state: data.state,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data
}

const purgeJSMinificationCache = async (applicationId: string): Promise<boolean> => {
  const response = await axios.delete(
    `${APPLICATION_URL}/${applicationId}/optimization/js/minify/purge`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data
}

const getJSLoadingMethod = async (
  applicationId: string
): Promise<{state: boolean; method: string}> => {
  const response = await axios.get(
    `${APPLICATION_URL}/${applicationId}/optimization/js/loading/state`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const updateJSLoadingMethod = async (applicationId: string, method: string): Promise<boolean> => {
  const response = await axios.put(
    `${APPLICATION_URL}/${applicationId}/optimization/js/loading/method`,
    {
      method,
    }
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data
}

export {
  getApplications,
  getApplicationById,
  getApplicationAccessLogs,
  getApplicationErrorLogs,
  getEdgeCacheStateByApplication,
  getDomainByApplication,
  getDomainStatus,
  createApplication,
  deleteApplication,
  deleteSelectedApplications,
  getApplicationWithMetadata,
  getAccessDetails,
  getFTPDetails,
  addDomainToApplication,
  getStagingStatusByApplication,
  updateApplicationStagingStatus,
  updateApplicationSyncStatus,
  initiateApplicationBackup,
  getApplicationBackups,
  getBackupSettings,
  setScheduledBackupSettings,
  restoreApplicationBackup,
  deleteApplicationBackup,
  installCertificate,
  updateApplication,
  getDatabaseOptimizationList,
  updateApplicationDatabaseOptimizations,
  getWebPOptimization,
  updateWebPOptimization,
  getPageCacheStateByApplication,
  updateApplicationPageCacheState,
  purgeApplicationCache,
  getObjectCacheStateByApplication,
  updateApplicationEdgeCacheState,
  updateApplicationObjectCacheState,
  purgeApplicationObjectCache,
  purgeApplicationOPCache,
  getHTMLMinifyStateByApplication,
  updateApplicationMinifyHTMLState,
  getCSSMinificationState,
  updateCSSMinificationState,
  purgeCSSMinificationCache,
  getJSMinificationState,
  updateJSMinificationState,
  purgeJSMinificationCache,
  getJSLoadingMethod,
  updateJSLoadingMethod,
  getBrowserCacheByApplication,
  updateApplicationBrowserCache,
  getDatabaseRepairList,
  repairDatabase,
  getNewrelicService,
  updateNewrelicService,
  getApplicationCrons,
  createApplicationCron,
  updateApplicationCron,
  deleteApplicationCron,
  getUptime,
  createUptime,
  deleteUptime,
  scanMalware,
  getSensitiveFilesAccessDetails,
  updateSensitiveFilesAccessDetails,
  getFirewallDetails,
  updateFirewallDetails,
  getPhpExecutionDetails,
  updatePhpExecutionDetails,
  getLockPluginThemeDetails,
  updateLockPluginThemeDetails,
  getFileEditorDetails,
  updateFileEditorDetails,
  getXmlRpcAccessDetails,
  updateXmlRpcAccessDetails,
  getHotlinkProtectionDetails,
  updateHotlinkProtectionDetails,
  getSecurityHeaders,
  updateSecurityHeaders,
  getPHPConf,
  updatePHPConf,
  getLockCoreFilesDetails,
  updateLockCoreFilesDetails,
}

import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DomainRecordsWrapper} from './domain-records/DomainRecords'

const domainBreadcrumbs: Array<PageLink> = [
  {
    title: 'Domain Settings',
    path: '/domain/:domainId/records',
    isSeparator: false,
    isActive: false,
  },
]

const DomainPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path=':domainId/records'
          element={
            <>
              <PageTitle breadcrumbs={domainBreadcrumbs}>Domain Records</PageTitle>
              <DomainRecordsWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/domain/:domainId/records' />} />
    </Routes>
  )
}

export default DomainPage

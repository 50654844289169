import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core/PageData'
import {DomainsListWrapper} from './domains-list/DomainsList'
import APIKeyPage from './APIKeyPage'
import KnowledgeBaseAccordion from '../../common/knowledgebase/KnowledgeBaseAccordion'

const cloudflareBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cloudflare Integration',
    path: '/integrations/cloudflare/list',
    isSeparator: false,
    isActive: false,
  },
]

/** ALIAS of DomainsPage as per our pattern **/
const CloudflarePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareBreadcrumbs}>Domain List</PageTitle>
              <DomainsListWrapper />
            </>
          }
        />
        <Route
          path='key'
          element={
            <>
              <PageTitle breadcrumbs={cloudflareBreadcrumbs}>API Details</PageTitle>
              <KnowledgeBaseAccordion
                body={`This page is to integrate users Cloudflare account with Cloudphant.
                <br>
                <br>
                Provide with the Cloudflare <b>account email and the api key</b> generated using all the <mark>below required permissions</mark> and save changes to integrate cloudflare
                <br>
                <br>
                <ol>
                  <li>Zone: Zone</li>
                  <li>Zone: Zone Settings</li>
                  <li>Zone: SSL and certificates</li>
                  <li>Zone: Cache purge</li>
                  <li>Zone: DNS</li>
                </ol>
                <br>
                Also make sure that <b>under zone resources, you include all zones</b>.
                `}
              />
              <APIKeyPage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/integrations/cloudflare/list' />} />
    </Routes>
  )
}

export default CloudflarePage
